<template>
  <v-dialog fullscreen theme="dark" v-model="dialog">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn class="addButton" color="#00473f" v-bind="activatorProps" size="small"> + Период </v-btn>
    </template>

    <v-card title="Добавить период">
      <form class="full_height_card" v-on:submit.prevent="dialog = false; $emit('accept', period)">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <v-select clearable v-model="period.items" :items="payment" hint="Выберите нужные квитанции"
                label="Квитанции" item-title="name" item-value="id" multiple>

                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-action>
                      <v-icon> {{ icon }} </v-icon>
                      <v-list-item-title>
                        &nbsp;Выделить все
                      </v-list-item-title>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="grey--text text-caption"> Выбрано: {{ period.items.length }} </span>
                </template>

              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" md="6" sm="6">
              <v-select :items="yearList" v-model="period.year" label="Год" item-title="label" item-value="value"
                required />
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-select :items="monthList" v-model="period.month" label="Месяц" item-title="label" item-value="value"
                required />
            </v-col>
          </v-row>

          <br>
          <CreatePayment method="create" @accept="addRow">
            <template v-slot:activator="{ onClick }">
              <v-btn @click="onClick" class="addButton" variant="tonal" color="success" size="small"> + Новая квитанция
              </v-btn>
            </template>
          </CreatePayment>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Отмена" variant="plain" @click="dialog = false"></v-btn>
          <v-btn color="primary" text="Сохранить" variant="tonal" type="submit"></v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>



<script>
import { defineComponent } from 'vue';
import CreatePayment from "@/components/uikit/CreatePayment.vue"
import { mapGetters } from "vuex";
import API from '@/api'
import { monthList, getMonthName, combineSelector } from '@/helpers/month';

export default defineComponent({
  name: "CreatePeriod",
  data() {
    return {
      dialog: false,
      yearList: [
        2022,
        2023,
        2024,
        2025,
      ],
      monthList: [],
      period: {
        year: 2025,
        month: 1,
        items: []
      },
    };
  },
  components: {
    CreatePayment
  },
  created() {
    this.monthList = monthList;
  },
  computed: {
    likesAllFruit() {
      return this.period.items.length === this.payment.length
    },
    likesSomeFruit() {
      return this.period.items.length > 0 && !this.likesAllFruit
    },
    icon() {
      if (this.likesAllFruit) return 'mdi-close-box'
      if (this.likesSomeFruit) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    ...mapGetters(['payment']),
    period() {
      if (this.payment) {
        return {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          items: this.payment.map((item) => (item.id)),
        }
      }
      return { year: 1, month: 1, items: [] }
    },
  },
  methods: {
    toggle() {
      console.log('here');

      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.period.items = []
        } else {
          this.period.items = this.payment;
        }
      })
    },
    addRow(data) {
      const body = {
        name: data.name,
        default_amount: String(data.default_amount) === "" ? null : data.default_amount,
      }
      API.createPayment(body).then(() => {
        API.fetchPayment().then((response) => {
          this.payment = response.data;
        });
      })
    },
  }
});
</script>


<style scoped>
.addButton {
  margin-bottom: 10px;
}

.full_height_card {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
