const MAP = {
    '1': 'Январь',
    '2': 'Февраль',
    '3': 'Март',
    '4': 'Апрель',
    '5': 'Май',
    '6': 'Июнь',
    '7': 'Июль',
    '8': 'Август',
    '9': 'Сентябрь',
    '10': 'Октябрь',
    '11': 'Ноябрь',
    '12': 'Декабрь',
};

export const getMonthName = (month) => MAP[month];
export const monthList = Object.keys(MAP).map((k) => ({ value: Number(k), label: getMonthName(k) }));

export const combinePeriod = (value) => {
    if (value?.month && value?.year) {
        return {
           label: `${getMonthName(value.month)} ${value.year}`,
           value: value,
       }
    }
    return {
        label: 'Выберите период',
        value: { year: null, month: null },
    }
}

export const combineSelector = (arr) => arr.map((v) => combinePeriod(v))



