<template>
  <MainLayout>

    <form v-if="jwt" class="input_form" v-on:submit.prevent="fetchLogout">
      <v-card theme="dark" class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
        <h3>Вы уже вошли в систему</h3>
        <div class="buttons">
          <v-btn @click="goTo('/')" class="mt-2" color="blue" size="large" variant="tonal" block>
            На главную
          </v-btn>
          <v-btn color="error" class="mb-8" size="large" type="submit" variant="tonal" block>
            Выйти
          </v-btn>
          
        </div>
      </v-card>
    </form>

    <form v-else class="input_form" v-on:submit.prevent="fetchLogin(authForm)">
      <v-card theme="dark" class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
        <v-text-field v-model="authForm.username" bg-color="transparent" required label="Логин"
          placeholder="Введите ваш логин"  variant="underlined"></v-text-field>
        <v-text-field v-model="authForm.password" required :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'" placeholder="Введите ваш пароль" label="Пароль" variant="underlined"
          @click:append-inner="visible = !visible"></v-text-field>
        <v-btn class="mb-8" color="blue" size="large" type="submit" variant="tonal" block>
          Войти
        </v-btn>
      </v-card>
    </form>



  </MainLayout>
</template>
  
  
<script lang="ts">
import { mapGetters, mapActions } from "vuex";
import MainLayout from '@/layouts/MainLayout.vue';
import { Auth } from '@/typing/main'
import router from '@/router';


export default {
  name: "LoginPage",
  data() {

    return {
      authForm: {
        username: "",
        password: "",
      } as Auth,
      visible: false
    };
  },
  components: {
    MainLayout,
  },
  props: {},
  computed: {
    ...mapGetters(["jwt", "errorMsg"]),
  },
  methods: {
    goTo(path:string) {
      router.push(path);
    },
    ...mapActions(["fetchLogin", "fetchLogout"]),
  },
  mounted() { },
};
</script>
  
  
  
<style  scoped>
.error-msg {
  margin-top: 10px;
  color: var(--red-color);
}

.buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

h1 {
  color:white
}
</style>
  