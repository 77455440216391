<template>
  <v-card class="mx-auto text-center mb-8" color="#212121" max-width="100%" dark>
    <v-card-text>
      <Bar :data="paymentChart" :options="chartOptions" />
    </v-card-text>
  </v-card>
</template>


<script>
import { defineComponent } from "vue"
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { mapGetters, mapActions } from "vuex";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default defineComponent({
  name: 'Chart',
  data() {
    return {
      chartOptions: {
        responsive: true
      },
    }
  },
  created() {
    this.fetchPaymentChart()
  },
  computed: {
    ...mapGetters(['paymentChart']),
  },
  components: {
    Bar
  },
  methods: {
    ...mapActions(['fetchPaymentChart']),

  },
})

</script>
