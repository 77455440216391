import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "activator", { onClick: _ctx.openDialog }, undefined, true),
    _createVNode(_component_v_dialog, {
      fullscreen: "",
      theme: "dark",
      modelValue: _ctx.dialog,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialog) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { title: _ctx.getTitle }, {
          default: _withCtx(() => [
            _createElementVNode("form", {
              class: "full_height_card",
              onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => {_ctx.dialog = false; _ctx.$emit('accept', _ctx.paymentLocal)}, ["prevent"]))
            }, [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { dense: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "12",
                        sm: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            autofocus: "",
                            modelValue: _ctx.paymentLocal.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paymentLocal.name) = $event)),
                            type: "text",
                            label: "Название",
                            required: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "12",
                        sm: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.paymentLocal.default_amount,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.paymentLocal.default_amount) = $event)),
                            type: "number",
                            label: "Сумма по умолчанию"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    text: "Отмена",
                    variant: "plain",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialog = false))
                  }),
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    text: "Сохранить",
                    variant: "tonal",
                    type: "submit"
                  })
                ]),
                _: 1
              })
            ], 32)
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}