<template>
  <div class="rating">
    <CreatePayment method="create"  @accept="addRow">
      <template v-slot:activator="{ onClick }">
        <v-btn @click="onClick" class="addButton" color="#00473f" size="small"> + Квитанция </v-btn> 
      </template>
    </CreatePayment>
    <v-table class="full-height-table" fixed-header theme="dark">
      <thead>
        <tr>
          <th class="text-left">Название</th>
          <th class="text-left">Сумма по умолчанию</th>
        </tr>
      </thead>
      <tbody>
        <CreatePayment method="update" :payment="item" @accept="saveRow" v-for="item in payment" :key="`payment_${item.id}`">
          <template v-slot:activator="{ onClick }">
            <tr @click="onClick">
              <td> {{ item.name }} </td>
              <td> {{ item.default_amount }} </td>
            </tr>
          </template>
        </CreatePayment>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
import CreatePayment from "@/components/uikit/CreatePayment.vue";
import axios from 'axios';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const payment = computed(() =>  store.getters.payment);
const fetchPayment = () => store.dispatch('fetchPayment');
const saveRow = (data) => {
      const body = {
        name: data.name,
        default_amount: String(data.default_amount) === "" ? null : data.default_amount,
      }
      axios.patch(`/api/bill/payment/${data.id}/`, body)
        .then(() => {
          fetchPayment();
        })
    }

const addRow = (data) => {
      const body = {
        name: data.name,
        default_amount: String(data.default_amount) === "" ? null : data.default_amount,
      }
      axios.post(`/api/bill/payment/`, body)
        .then(() => {
          fetchPayment();
        })
    }

</script>



<style scoped>

tr {
  cursor: pointer;
}

.rating {
  margin-bottom: 60px;
}

.full-height-table {
  height: calc(100dvh - 80px - 68px - 38px - 60px);
}

.addButton {
  margin-bottom: 10px;
}

</style>