<template>
  <MainLayout>
    <v-tabs class="mb-5" v-model="tab" bg-color="rgb(0, 71, 63)" color="rgb(2, 188, 166)">
      <v-tab value="1"> Периоды </v-tab>
      <v-tab value="2"> Все квитанции </v-tab>
      <v-tab value="3"> Динамика</v-tab>
    </v-tabs>

    <v-tabs-window v-model="tab">
      <v-tabs-window-item value="1">
        <BillsApp :currentPeriod="currentPeriod" />
      </v-tabs-window-item>

      <v-tabs-window-item value="2">
        <Payment />
      </v-tabs-window-item>

      <v-tabs-window-item value="3">
        <Chart />
      </v-tabs-window-item>
    </v-tabs-window>

  </MainLayout>
</template>


<script setup>
import { useStore } from 'vuex'
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import MainLayout from '@/layouts/MainLayout.vue';
import BillsApp from "@/components/BillsApp.vue";
import Payment from "@/components/Payment.vue";
import Chart from "@/components/Chart.vue";


const tab = defineModel();
const route = useRoute();
const store = useStore();


const fetchPeriodList = () => store.dispatch('fetchPeriodList')
const fetchPayment = () => store.dispatch('fetchPayment')

const currentPeriod = computed(() => {
  return {
    year: route?.params?.year || null,
    month: route?.params?.month || null,
  }
})


onMounted(() => {
  fetchPeriodList();  
  fetchPayment();
})

</script>
