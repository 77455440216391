<template>
  <div class="rating">
    <div class="controlButtons">
      <CreatePeriod @accept="addPeriod" />
    </div>
    <PeriodSelector />
    <v-table class="full-height-table" fixed-header theme="dark">
      <thead>
        <tr>
          <th class="text-left"></th>
          <th class="text-left">Квитанция</th>
          <th class="text-left">Сумма</th>
        </tr>
      </thead>
      <tbody>
        <UpdateBill :bill="bill" @accept="saveRow" v-for="bill in bills" :key="`bill_${bill.id}`" />
      </tbody>
    </v-table>
    <v-card theme="dark" text="Потрачено за период" :title="`${billsSum} р.`">
    </v-card>
  </div>
</template>


<script setup>

import { defineProps, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import API from '@/api';
import PeriodSelector from "./PeriodSelector.vue";
import UpdateBill from "@/components/uikit/UpdateBill.vue";
import CreatePeriod from "@/components/uikit/CreatePeriod.vue";
const $math = require('mathjs')

const store = useStore();
const router = useRouter();
const route = useRoute();

const props = defineProps(['currentPeriod'])

const bills = computed(() => store.getters.bills);
const billsSum = computed(() => {
  const numbers = store.getters.bills.map((i) => Number(i.amount))
  return $math.round($math.sum(numbers))
})

const fetchBills = (data) => store.dispatch('fetchBills', data);
const fetchPeriodList = () => store.dispatch('fetchPeriodList');

const addPeriod = (data) => {
  API.createBill(data).then(() => {
    fetchBills(data);
    router.push(`/period/${data.year}/${data.month}`);
    fetchPeriodList();
  })
}

const saveRow = (data) => {
  API.updateBill(
    data.id,
    {
      status: data.status,
      amount: data.amount,
      presave: data.presave,
    }
  ).then(() => {
    fetchSafeBills();
  })
}

const fetchSafeBills = () => {
  if (route?.params?.year) {
    fetchBills(route.params);
  }
}

watch(route, () => {
  fetchSafeBills();
})

onMounted(() => {
  fetchSafeBills();
})

</script>


<style scoped>
tr {
  cursor: pointer;
}

.dark {
  color: white;
}

.rating {
  margin-bottom: 60px;
}

.full-height-table {
  height: calc(100dvh - 80px - 68px - 38px - 60px - 100px);
}

.controlButtons {
  display: flex;
  gap: 10px;
}
</style>