<template>
    <slot name="activator" :onClick="openDialog" ></slot>
    <v-dialog fullscreen theme="dark" v-model="dialog">
      <v-card :title="getTitle">
        <form class="full_height_card" v-on:submit.prevent="dialog = false; $emit('accept', paymentLocal)">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <v-text-field autofocus v-model="paymentLocal.name" type="text" label="Название" required />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field v-model="paymentLocal.default_amount" type="number" label="Сумма по умолчанию" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Отмена" variant="plain" @click="dialog = false"></v-btn>
          <v-btn color="primary" text="Сохранить" variant="tonal" type="submit"></v-btn>
        </v-card-actions>
      </form>
      </v-card>
    </v-dialog>
</template>



<script lang="ts">
import { PaymentType } from '@/typing/main'
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: "CreatePayment",
  props: {
    method: {
      type: String,
      required: true
    },
    payment: {
      type: Object as PropType<PaymentType>,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      dialog: false,
      paymentLocal: {} as PaymentType,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    }
  },
  created() {
    this.paymentLocal = this.payment;
  },
  computed: {
    getTitle() {
      if (this.method === 'create') {
        return 'Новая квитанция'
      }
      if (this.method === 'update') {
        return 'Изменить квитанцию'
      }
    }
  }
});
</script>


<style scoped>

tr:hover {
  background-color: black;
}

tr {
  cursor: pointer;
}

.icon_status {
  width: 24px;
  height: 24px;
}

.full_height_card {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

</style>
