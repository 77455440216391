<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ props: activatorProps }">
      <tr v-bind="activatorProps">
        <td>
          <div class="icon_status" v-if="bill.status === 1">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#BBB" height="100%"
              width="100%" version="1.1" id="Capa_1" viewBox="0 0 53 53" xml:space="preserve">
              <g>
                <path
                  d="M45.707,10.075l-9.794-9.783C35.725,0.105,35.471,0,35.206,0H8C7.448,0,7,0.448,7,1v51c0,0.552,0.448,1,1,1h37   c0.552,0,1-0.448,1-1V10.783C46,10.517,45.895,10.263,45.707,10.075z M42.586,10H36V3.414L42.586,10z M9,51V2h25v9   c0,0.552,0.448,1,1,1h9v39H9z" />
              </g>
            </svg>
          </div>
          <div class="icon_status" v-else-if="bill.status === 2">
            <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 14L8.23309 16.4248C8.66178 16.7463 9.26772 16.6728 9.60705 16.2581L18 6" stroke="green"
                stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>
          <div class="icon_status" v-else-if="bill.status === 3">
            <svg fill="grey" width="100%" height="100%" viewBox="0 0 32 32" version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.587 16.001l6.096 6.096c0.396 0.396 0.396 1.039 0 1.435l-2.151 2.151c-0.396 0.396-1.038 0.396-1.435 0l-6.097-6.096-6.097 6.096c-0.396 0.396-1.038 0.396-1.434 0l-2.152-2.151c-0.396-0.396-0.396-1.038 0-1.435l6.097-6.096-6.097-6.097c-0.396-0.396-0.396-1.039 0-1.435l2.153-2.151c0.396-0.396 1.038-0.396 1.434 0l6.096 6.097 6.097-6.097c0.396-0.396 1.038-0.396 1.435 0l2.151 2.152c0.396 0.396 0.396 1.038 0 1.435l-6.096 6.096z">
              </path>
            </svg>
          </div>
          <div v-else>
            ?
          </div>

        </td>
        <td> {{ bill.payment.name }} </td>
        <td> {{ bill.amount === null ? '-' : bill.amount }} </td>
      </tr>
    </template>

    <v-card :title="`Квитанция: ${bill.payment.name}`">
      <form v-on:submit.prevent="dialog = false; $emit('accept', billLocal)">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <h3>{{ `Период: ${bill.month}.${bill.year}` }}</h3>
            </v-col>

            <v-col cols="12" md="8" sm="12">
              <v-text-field autofocus v-model="billLocal.amount" type="number" label="Сумма" required />
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <v-select :items="statusList" v-model="billLocal.status" label="Статус" item-title="label"
                item-value="value" required />
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-checkbox color="primary" v-model="billLocal.presave"
                label="Сохранить сумму на следующий месяц"></v-checkbox>
            </v-col>
          </v-row>

          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text="Отмена" variant="plain" @click="dialog = false"></v-btn>
          <v-btn color="primary" text="Сохранить" variant="tonal" type="submit"></v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>



<script lang="ts">
import { PeriodPaymentType } from '@/typing/main'
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: "UpdateBill",
  props: {
    bill: {
      type: Object as PropType<PeriodPaymentType>,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      billLocal: {} as PeriodPaymentType,
      statusList: [
        {
          label: "Создан",
          value: 1
        },
        {
          label: "Оплачено",
          value: 2
        },
        {
          label: "Пропущено",
          value: 3
        },

      ],
    };
  },
  created() {
    this.billLocal = {...this.bill};
  },
});
</script>


<style scoped>
tr:hover {
  background-color: black;
}

tr {
  cursor: pointer;
}

.icon_status {
  width: 24px;
  height: 24px;
  
}
</style>
