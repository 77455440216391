<template>

    <v-card theme="dark">
        <v-select theme="dark" item-title="label" item-value="value" :items="combineSelector(periodList)"
            v-model="selected" @update:modelValue="onSelect" return-object></v-select>
    </v-card>

</template>


<script setup>
import { computed, defineEmits, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { combineSelector, combinePeriod } from '@/helpers/month';


const route = useRoute();
const router = useRouter();
const selected = defineModel();
const store = useStore();

selected.value = {
    label: 'Выберите период',
    value: { year: null, month: null },
}

const periodList = computed(() => store.getters.periodList)

const onSelect = (val) => {
    router.replace(`/period/${val.value.year}/${val.value.month}`);
}


watch(periodList, (v) => {
    const p_year = Number(route.params.year);
    const p_month = Number(route.params.month);
    const result = v.find(({ year, month }) => year === p_year &&  month === p_month);
    if (result)
        selected.value = combinePeriod(result)
})


</script>


<style scoped></style>