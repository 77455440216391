import router from '@/router';
import axios from 'axios';
import { createStore } from 'vuex'
import API from '@/api'


export default createStore({
  state: {
    jwt: localStorage.getItem('jwt') || '',
    errorMsg: '',
    user: {},
    bills: [],
    payment: [],
    periodList: [],
    paymentChart: {
      labels: [],
      datasets: [],
    },
  },
  getters: {
    isAuhenticated: (state) => !!state.jwt,
    jwt: (state) => state.jwt,
    errorMsg: (state) => state.errorMsg,
    userMe: (state) => state.user,
    bills: (state) => state.bills,
    payment: (state) => state.payment,
    periodList: (state) => state.periodList,
    paymentChart: (state) => state.paymentChart,
    Authorization(state) {
      let jwt = state.jwt;
      return (jwt) ? `Token ${jwt}` : '';
    },
  },
  mutations: {
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setToken(state, jwt) {
      state.jwt = String(jwt);
      localStorage.setItem('jwt', jwt);
      state.errorMsg = '';
    },
    removeToken(state) {
      localStorage.removeItem('jwt');
      state.jwt = '';
    },
    setUser(state, value) {
      state.user = value;
    },
    setBills(state, data) {
      state.bills = data;
    },
    setPayment(state, data) {
      state.payment = data;
    },
    setPeriodList(state, data) {
      state.periodList = data;
    },
    setPaymentChart(state, data) {
      state.paymentChart = data;
    },
  },
  actions: {
    fetchLogin(ctx, data) {
      axios.post(`/api/auth/token/`, data, {})
        .then((response) => {
          ctx.commit('setToken', response.data.token);
          router.push('/');
        })
        .catch((err) => {
          ctx.commit('setErrorMsg', 'Неверный логин или пароль');
        });
    },
    fetchUserMe(ctx) {
      axios.get(`/api/auth/user/me/`)
        .then((response) => {
          ctx.commit('setUser', response.data);
        })
    },

    fetchPeriodList(ctx) {
      API.fetchPeriodList().then((response) => {
          ctx.commit('setPeriodList', response.data);
      })
    },
    fetchLogout(ctx) {
      ctx.commit('removeToken');
      ctx.commit('setUser', {});
      router.push('/');
    },

    fetchBills(ctx, {year, month}) {
      API.fetchBills(year, month).then((response) => {
        ctx.commit('setBills', response.data);
        })
    },

    fetchPayment(ctx) {
      API.fetchPayment().then((response) => {
          ctx.commit('setPayment', response.data);
        })
    },
    fetchPaymentChart(ctx) {
      API.fetchPaymentChart().then((response) => {
          let data = response.data.reverse();
          const chartData = {
            labels: data.map((i: { month: any; year: any; }) => `${i.month}.${String(i.year).slice(-2)}`),
            datasets: [{
              data: data.map((i: { sum: any; }) => i.sum),
              backgroundColor: '#02bca6',
              label: 'Динамика',
            }],
          }
          ctx.commit('setPaymentChart', chartData);
        })
    },
  },
  modules: {}
})



